<template>
  <section v-if="inputList?.length > 0 && id == formId" class="form-container">
    <div class="container">
      <f7-list class="formContainer" form no-hairlines no-hairlines-between @submit="onFormSubmit">
        <div v-for="(inputGroup, groupIndex) in inputList" :key="'ing_' + inputGroup.name" class="form-group-container">
          <template v-if="!inputGroup?.visibleSelector || (inputGroup?.visibleSelector && inputGroup?.visibleValue && formData[inputGroup?.visibleSelector] == inputGroup?.visibleValue)">
            <f7-list-item v-if="inputGroup?.name && !inputGroup?.hidden" divider>
              {{ inputGroup.name }}
              <template v-if="inputGroup?.required === true">
                <f7-link tabindex="-1" class="form-required no-ripple">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>

              <f7-toggle :key="'ftog_' + groupIndex" v-model:checked="inputGroup.isVisible" class="toggle-visibility" @change="onToggleVisibility"></f7-toggle>
            </f7-list-item>

            <transition name="slide-fade">
              <div v-if="inputGroup.isVisible && !inputGroup?.hidden" :class="{ row: inputGroup?.isRow }">
                <template v-if="inputGroup?.type === 'selectgeolocation' && !inputGroup?.hidden">
                  <div v-if="formData[inputGroup?.value + 'List']?.length > 0" class="geo-map">
                    <img :src="$h.getMapBoxStaticMap(formData[inputGroup?.value + 'List'])" :alt="$t.getTranslation('IMG_ALT_MAP_IMAGE')" />
                  </div>

                  <f7-button class="button-select" fill large @click="onSelectGeoLocation(inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SELECT_ITEM') }}</f7-button>
                </template>

                <template v-if="inputGroup?.type === 'selectschedule' && !inputGroup?.hidden">
                  <div v-if="formData[inputGroup?.value + 'List']?.length > 0" class="schedule-container">
                    <div v-for="(schedule, scheduleIndex) in formData[inputGroup?.value + 'List']" :key="'sch_' + scheduleIndex" class="schedule-item">
                      <h2>{{ schedule.Name }}</h2>

                      <ul class="schedule-time">
                        <template v-if="schedule?.timeList?.length > 0">
                          <li v-for="(time, timeIndex) in schedule?.timeList" :key="'sct_' + timeIndex">{{ time.StartTime }} - {{ time.EndTime }}</li>
                        </template>
                        <template v-else>
                          <li class="all">{{ $t.getTranslation('PNL_ALL_TIME') }}</li>
                        </template>
                      </ul>
                    </div>
                  </div>

                  <f7-button class="button-select" fill large @click="onSelectSchedule(inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SELECT_ITEM') }}</f7-button>
                  <p v-if="inputGroup?.info" class="form-group-info">{{ inputGroup?.info }}</p>
                </template>

                <template v-if="inputGroup?.type === 'selectmultiple' && !inputGroup?.hidden">
                  <f7-list-item class="selectmultiple-container">
                    <template v-if="inputGroup?.listingDesign === 'blocks'">
                      <ul v-if="formData[inputGroup?.value + 'List']?.length > 0" class="blocks">
                        <li v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_sml_' + item[inputGroup?.params?.valueSelector] || index">
                          {{ item[inputGroup?.params?.nameSelector || 'name'] }}
                        </li>
                      </ul>
                    </template>

                    <template v-else>
                      <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list sortable sortable-enabled class="multipleitem-container" @sortable:sort="onSelectMultipleSort($event, inputGroup.value + 'List')">
                        <f7-list-item
                          v-for="(item, index) in formData[inputGroup.value + 'List']"
                          :key="inputGroup.value + '_sml_' + item[inputGroup?.params?.valueSelector] || index"
                          :title="item[inputGroup?.params?.nameSelector || 'name']"
                          :text="item[inputGroup?.params?.descriptionSelector || 'description'] || $t.getTranslation('PNL_NO_DESCRIPTION')"
                        >
                          <template #media>
                            <img
                              v-if="inputGroup?.params?.imageSelector && inputGroup?.params?.imageBucket"
                              :src="$h.getImage(item[inputGroup?.params?.imageSelector || 'Image'], inputGroup?.params?.imageBucket)"
                              :alt="$t.getTranslation('IMG_ALT_ITEM_IMAGE')"
                            />
                            <img v-else :src="require('@/assets/images/placeholder.jpg')" :alt="$t.getTranslation('IMG_ALT_PLACEHOLDER_IMAGE')" />
                          </template>
                        </f7-list-item>
                      </f7-list>
                    </template>

                    <f7-button class="button-select" fill large @click="onSelectMultiple(inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SELECT_ITEM') }}</f7-button>
                    <p v-if="inputGroup?.info" class="form-group-info">{{ inputGroup?.info }}</p>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'price' && !inputGroup?.hidden">
                  <f7-list-item class="price-container">
                    <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list class="multipleitem-container">
                      <f7-list-item v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_sml_' + item['CurrencyId'] || index">
                         <template #title>
                          <template v-if="item?.PriceFinal"> {{ item.CurrencySymbol }}{{ item.PriceFinal }}</template>
                        </template>
                        <template #text>
                          <strong>{{ item.CurrencyCode }}</strong> - {{ item.CurrencyName }}
                        </template>
                        <template #media>
                          <img :src="require('@/assets/images/currency.jpg')" :alt="$t.getTranslation('IMG_ALT_PRICE_PLACEHOLDER_IMAGE')" />
                        </template>
                        <template #content>
                          <div class="button-container" @click.stop="onEditPrice(inputGroup, item, index)">
                            <f7-button fill color="blue">
                              <font-awesome-icon :icon="['far', 'pencil']" fixed-width />
                            </f7-button>
                            <f7-button fill color="red" @click.stop="onDeletePrice(inputGroup, item, index)">
                              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                            </f7-button>
                          </div>
                        </template>
                      </f7-list-item>
                    </f7-list>

                    <f7-button class="button-select" fill large @click="onSetPrice(inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SET_PRICE') }}</f7-button>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'priceProduct' && !inputGroup?.hidden">
                  <f7-list-item class="price-container">
                    <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list class="multipleitem-container">
                      <f7-list-item v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_sml_' + item['CurrencyId'] || index">
                         <template #title>
                          <template v-if="item?.PriceFinal"> {{ item.CurrencySymbol }}{{ item.PriceFinal }}</template>
                        </template>
                        <template #text>
                          <strong>{{ item.CurrencyCode }}</strong> - {{ item.CurrencyName }}
                        </template>
                        <template #media>
                          <img :src="require('@/assets/images/currency.jpg')" :alt="$t.getTranslation('IMG_ALT_PRICE_PLACEHOLDER_IMAGE')" />
                        </template>
                        <template #content>
                          <div class="button-container" @click.stop="onEditPrice(inputGroup, item, index)">
                            <f7-button fill color="blue">
                              <font-awesome-icon :icon="['far', 'pencil']" fixed-width />
                            </f7-button>
                            <f7-button fill color="red" @click.stop="onDeletePrice(inputGroup, item, index)">
                              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                            </f7-button>
                          </div>
                        </template>
                      </f7-list-item>
                    </f7-list>

                    <f7-button class="button-select" fill large @click="onSetProductPrice(inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SET_PRICE') }}</f7-button>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'translation' && !inputGroup?.hidden">
                  <f7-list-item class="translation-container">
                    <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list class="multipleitem-container">
                      <f7-list-item v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_tlc_' + item['TranslationId'] || index">
                        <template #title>
                          <strong>{{ item.LanguageName }}</strong>
                        </template>
                        <template #text>
                          <template v-for="(content, key) in item" :key="inputGroup.value + '_tlc_c' + key">
                            <span v-if="['LanguageId', 'LanguageName', 'LanguageCode'].indexOf(key) <= -1" class="translation-content"> {{ content }} </span>
                          </template>
                        </template>
                        <template #media>
                          <div class="language-media">
                            {{ item.LanguageCode }}
                          </div>
                        </template>
                        <template #content>
                          <div class="button-container" @click.stop="onEditTranslation(inputGroup, item, index)">
                            <f7-button fill color="blue">
                              <font-awesome-icon :icon="['far', 'pencil']" fixed-width />
                            </f7-button>
                            <f7-button fill color="red" @click.stop="onDeleteTranslation(inputGroup, item, index)">
                              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                            </f7-button>
                          </div>
                        </template>
                      </f7-list-item>
                    </f7-list>

                    <f7-button class="button-select" fill large @click="onSetTranslation(inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SET_TRANSLATION') }}</f7-button>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'image' && !inputGroup?.hidden">
                  <f7-list-item class="image-container">
                    <div class="image-item image-upload" @click="onSelectImage(inputGroup?.value)">
                      <font-awesome-icon :icon="['far', 'plus']" fixed-width />

                      <ImageUploadPopupComponent
                        ref="imageUploadPopupComponent"
                        :params="{
                          type: inputGroup?.value
                        }"
                        :image-size-limit="inputGroup?.sizeLimit"
                        :canvas="inputGroup?.canvas"
                        :stencil-size="inputGroup?.stencilSize"
                        :stencil-props="inputGroup?.stencilProps"
                        :backdrop="true"
                        @cropped="onImageSelected"
                      />
                    </div>

                    <div v-for="(image, index) in formData[inputGroup.value + 'List']" :key="'i_' + index" :class="{ default: image?.IsDefault === 1 }" class="image-item" @click.stop="toggleDefaultImage(inputGroup?.value, index)">
                      <div class="select">
                        <font-awesome-icon :icon="['far', 'star']" fixed-width />
                      </div>

                      <template v-if="image?.blob">
                        <img :src="$h.renderImageBlob(image?.blob)" :alt="inputGroup?.value" />
                      </template>

                      <template v-if="image?.image">
                        <img :src="image?.image" :alt="inputGroup?.value" />
                      </template>

                      <template v-if="image?.Type && image?.FileName">
                        <img :src="$h.getImage(image.FileName, image?.Type)" :alt="inputGroup?.value" />
                      </template>

                      <f7-button class="delete" @click.stop="deleteImage(inputGroup?.value, index, image?.FileId)">
                        <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                      </f7-button>
                    </div>
                  </f7-list-item>
                  <div class="image-description">
                    <div v-if="inputGroup?.value === 'Image'">
                      <p>
                        <span>{{ $t.getTranslation('PNL_IMAGE_DESCRIPTION') }}</span> <span class="recommend">{{ $t.getTranslation('PNL_IMAGE_DESCRIPTION_RECOMMENDATION') }}</span>
                      </p>
                    </div>
                    <div v-if="inputGroup?.value === 'BannerImage'">
                      <p class="recommend">
                        {{ inputGroup.isProduct ? $t.getTranslation('PNL_PRODUCT_IMAGE_DESCRIPTION_RECOMMENDATION') : $t.getTranslation('PNL_BANNER_IMAGE_DESCRIPTION_RECOMMENDATION') }}
                      </p>
                      <p>
                        {{ inputGroup.isProduct ? $t.getTranslation('PNL_PRODUCT_IMAGE_DESCRIPTION') : $t.getTranslation('PNL_BANNER_IMAGE_DESCRIPTION') }}
                      </p>
                    </div>
                  </div>
                </template>

                <template v-if="inputGroup?.type === 'upload' && !inputGroup?.hidden">
                  <f7-list-item class="upload-container">
                    <ul class="file-upload-container">
                      <li v-for="(fileItem, index) in formData[inputGroup.value + 'List']" :key="'fu_' + index">
                        <template v-if="fileItem?.FileId">
                          <div class="image">
                            <font-awesome-icon v-if="['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml'].indexOf(fileItem?.MimeType) > -1" :icon="['fas', 'image']" fixed-width />
                            <font-awesome-icon v-else :icon="['fas', 'file']" fixed-width />
                          </div>

                          <div class="content">
                            <h3>{{ fileItem?.FileName }}</h3>
                            <small>{{ fileItem?.MimeType }} - {{ $h.formatFileSize(fileItem?.Size) }}</small>
                          </div>
                        </template>
                        <template v-else>
                          <div class="image">
                            <font-awesome-icon v-if="['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml'].indexOf(fileItem?.file?.type) > -1" :icon="['fas', 'image']" fixed-width />
                            <font-awesome-icon v-else :icon="['fas', 'file']" fixed-width />
                          </div>

                          <div class="content">
                            <h3>{{ fileItem?.file?.name }}</h3>
                            <small>{{ fileItem?.file?.type }} - {{ $h.formatFileSize(fileItem?.file?.size) }}</small>
                          </div>
                        </template>

                        <div class="button-container">
                          <f7-button class="delete" @click.stop="onDeleteFile(inputGroup?.value, index, fileItem?.FileId)">
                            <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                          </f7-button>
                        </div>
                      </li>
                    </ul>

                    <input ref="fileUploadInput" type="file" accept="image/png,image/jpeg,image/webp,image/svg+xml,application/pdf" @change="onFileUploadChange($event, inputGroup)" />

                    <f7-button class="button-upload" fill large @click="onSelectFile($refs, inputGroup)">{{ inputGroup?.buttonText || $t.getTranslation('PNL_SELECT_ITEM') }}</f7-button>
                  </f7-list-item>

                  <div class="upload-description">
                    <p>{{ $t.getTranslation('PNL_FILE_UPLOAD_DESCRIPTION') }}</p>
                  </div>
                </template>

                <template v-if="inputGroup?.type === 'input'">
                  <template v-for="input in inputGroup?.list" :key="'inp_' + input.value">
                    <template v-if="input.type === 'tags'">
                      <!-- {{ 'tag' }} -->
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        :class="['tags', input?.rowWidth || 'col-100']"
                        type="text"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        floating-label
                        outline
                        validate
                        :clear-button="!input.hideClearButton"
                        @change="input?.onChange ? input['onChange']($event, formData) : ''"
                        @blur="onInputBlur($event, input)"
                        @keyup.enter="onAddTag(input.value, input?.unique)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                        <template #content-end>
                          <f7-button fill large @click="onAddTag(input.value, input?.unique)">{{ $t.getTranslation('PNL_ADD') }}</f7-button>
                        </template>
                        <template v-if="formData?.[input.value + 'List']?.length > 0" #root>
                          <f7-chip v-for="(item, index) in formData?.[input.value + 'List']" :key="'chp_' + index" :text="item" deleteable @click="onDeleteTag(input.value, item, index)"></f7-chip>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'texteditor'">
                      <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet" />
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        :class="['texteditor', input?.rowWidth || 'col-100']"
                        type="texteditor"
                        :text-editor-params="{ mode: 'toolbar', divider: true, clearFormattingOnPaste: true }"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        @texteditor:change="(value) => (formData[input.value] = value)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'timepicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        :class="['timepicker', input?.rowWidth || 'col-100']"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        :clear-button="!input.hideClearButton"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'durationpicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        :class="['durationpicker', input?.rowWidth || 'col-100']"
                        :increment="input.increment"
                        :maxhour="input.maxhour"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        :clear-button="!input.hideClearButton"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'datepicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        :class="['datepicker', input?.rowWidth || 'col-100']"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        :data-default-date="input?.defaultDate"
                        :data-start-year="input?.startYear"
                        outline
                        floating-label
                        validate
                        :clear-button="!input.hideClearButton"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'datetimepicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        :class="['datetimepicker', input?.rowWidth || 'col-100']"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        :clear-button="!input.hideClearButton"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'text-optional'">
                      <f7-list-input
                        v-if="!input.hidden && formData[input.condition.field] == input.condition.value"
                        v-model:value="formData[input.value]"
                        :class="[input.type, input?.rowWidth ? input.rowWidth : 'col-100']"
                         type="text"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        :acceptinput="input?.acceptinput"
                        floating-label
                        outline
                        validate
                        :clear-button="!input.hideClearButton"
                        @change="input?.onChange ? input['onChange']($event, formData) : ''"
                        @blur="onInputBlur($event, input)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>

                        <template v-if="input.type === 'password'" #content-end>
                          <f7-link tabindex="-1" class="show-password" @click="onTogglePassword">
                            <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
                          </f7-link>
                        </template>

                        <template v-if="input.type === 'select'">
                          <template v-for="item in input?.items" :key="'sl_' + item[input?.valueSelector || 'value']">
                            <option
                              v-if="item[input?.valueSelector || 'value'] == '' || !input?.filter || (input?.filter && formData[input?.filter] == '') || (input?.filter && formData[input?.filter] == item[input?.filter])"
                              :value="item[input?.valueSelector || 'value']"
                            >
                              {{ item[input?.nameSelector || 'name'] }}
                            </option>
                          </template>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else>
                      <f7-list-input
                        v-if="!input.hidden"
                        v-model:value="formData[input.value]"
                        :class="[input.type, input?.rowWidth ? input.rowWidth : 'col-100']"
                        :type="input.type"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        :acceptinput="input?.acceptinput"
                        floating-label
                        outline
                        validate
                        :clear-button="!input.hideClearButton"
                        @change="input?.onChange ? input['onChange']($event, formData) : ''"
                        @blur="onInputBlur($event, input)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required no-ripple">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>

                        <template v-if="input.type === 'password'" #content-end>
                          <f7-link tabindex="-1" class="show-password" @click="onTogglePassword">
                            <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
                          </f7-link>
                        </template>

                        <template v-if="input.type === 'select'">
                          <template v-for="item in input?.items" :key="'sl_' + item[input?.valueSelector || 'value']">
                            <option
                              v-if="item[input?.valueSelector || 'value'] == '' || !input?.filter || (input?.filter && formData[input?.filter] == '') || (input?.filter && formData[input?.filter] == item[input?.filter])"
                              :value="item[input?.valueSelector || 'value']"
                            >
                              {{ item[input?.nameSelector || 'name'] }}
                            </option>
                          </template>
                        </template>
                      </f7-list-input>
                    </template>
                  </template>
                </template>
              </div>
            </transition>
          </template>
        </div>
      </f7-list>

      <div :class="{ 'button-fixed-container': buttonLocation === 'FIXED' }">
        <f7-button fill large raised preloader :disabled="isButtonProcessing" @click="onProcessSave">
          {{ buttonText }}
        </f7-button>
      </div>
      {{ inputGroup }}
      <SelectMultiplePopupComponent v-if="hasSelectMultiple" ref="selectMultiplePopupComponent" :backdrop="true" @selected="onMultipleSelected" />
      <SelectGeoLocationPopupComponent v-if="hasSelectGeoLocation" ref="selectGeoLocationPopupComponent" :backdrop="true" @selected="onGeoLocationSelected" />
      <SelectSchedulePopupComponent v-if="hasSelectSchedule" ref="selectSchedulePopupComponent" :backdrop="true" @selected="onScheduleSelected" />
      <SetPricePopupComponent v-if="hasSetPrice" ref="setPricePopupComponent" :backdrop="true" @set="onPriceSet" />
      <SetPriceProductPopupComponent v-if="hasSetProductPrice" ref="setPriceProductPopupComponent" :backdrop="true" @set="onProductPriceSet" />
      <SetTranslationPopupComponent v-if="hasSetTranslation" ref="setTranslationPopupComponent" :backdrop="true" @set="onTranslationSet" />
    </div>
  </section>

  <template v-else>
    <div class="preloader-container">
      <f7-preloader></f7-preloader>
    </div>
  </template>
</template>

<script>
import { defineComponent, ref, inject, computed } from 'vue'
import { Dom7 } from 'framework7'

import { get, post } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import { validations } from '@/utils/validations'

import { useStore } from '@/store'
const store = useStore()

import ImageUploadPopupComponent from '@/components/ImageUploadPopupComponent.vue'
import SelectMultiplePopupComponent from '@/components/SelectMultiplePopupComponent.vue'
import SelectGeoLocationPopupComponent from '@/components/SelectGeoLocationPopupComponent.vue'
import SelectSchedulePopupComponent from '@/components/SelectSchedulePopupComponent.vue'
import SetPricePopupComponent from '@/components/SetPricePopupComponent.vue'
import SetPriceProductPopupComponent from '@/components/SetPriceProductPopupComponent.vue'
import SetTranslationPopupComponent from '@/components/SetTranslationPopupComponent.vue'

export default defineComponent({
  name: 'FormComponent',
  components: {
    ImageUploadPopupComponent,
    SelectMultiplePopupComponent,
    SelectGeoLocationPopupComponent,
    SelectSchedulePopupComponent,
    SetPricePopupComponent,
    SetTranslationPopupComponent,
    SetPriceProductPopupComponent
  },
  props: {
    id: String
  },
  emits: ['successSave'],
  setup(props, { emit }) {
    const $t = inject('$translation')

    const isUpdate = ref(false)
    const isButtonProcessing = ref(false)
    const isFormUpload = ref(false)

    const hasSelectMultiple = ref(false)
    const hasSelectGeoLocation = ref(false)
    const hasSelectSchedule = ref(false)
    const hasSetPrice = ref(false)
    const hasSetTranslation = ref(false)
    const hasSetProductPrice = ref(false)

    const formId = computed(() => store.getters['form/getFormId'])
    const formData = computed(() => store.getters['form/getFormData'])
    const inputList = computed(() => store.getters['form/getInputList'])

    const buttonText = ref($t.getTranslation('PNL_SAVE'))
    const buttonLocation = ref('FIXED')

    const loginType = store.getters['page/getData']?.loginType
    const isServicePartner = loginType === 'SERVICEPARTNER' ? true : false

    let formComponentData = {}
    let validationRuleList = {}
    let validationRules = {}
    let inputListValueMap = {}

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData.value,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const onTogglePassword = (event) => {
      try {
        const formElement = Dom7(event.target).parent().parent().parent()
        const inputElement = formElement.find('input')
        if (inputElement.length > 0) {
          const passwordAttribute = inputElement.attr('type')
          inputElement.attr('type', passwordAttribute === 'text' ? 'password' : 'text')
        }
      } catch (error) {
        console.error('Error toggling password:', error)
      }
    }

    const onToggleVisibility = () => {
      window.setTimeout(helpers.initFormMasonry, 600)
    }

    const onInputBlur = (event, input) => {
      if (input?.transformValue) {
        switch (input?.transformValue) {
          case 'CODE':
            if (formData.value?.[input?.value] != '') {
              formData.value[input.value] = formData.value?.[input?.value]
                .replace(/[^a-zA-Z0-9 ]/g, '_')
                .replace(/ /g, '_')
                .toUpperCase()
            }
            break
        }
      }

      if (input?.autoFill) {
        if (formData.value?.[input?.value] != '' && formData.value?.[input?.autoFill] === '') {
          formData.value[input.autoFill] = formData.value?.[input?.value]
            .replace(/[^a-zA-Z0-9 ]/g, '_')
            .replace(/ /g, '_')
            .toUpperCase()
        }
      }
    }

    const onMultipleSelected = (data) => {
      if (data?.type && data?.list) {
        formData.value[data?.type + 'List'] = data?.list
        window.setTimeout(helpers.initFormMasonry, 600)
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === 'selectmultiple' && inputGroup?.value === data?.type) {
          if (inputGroup?.onChange) inputGroup?.onChange(data?.list, formData.value)
        }
      })
    }

    const onGeoLocationSelected = (data) => {
      if (data?.type && data?.list) {
        formData.value[data?.type + 'List'] = data?.list
        window.setTimeout(helpers.initFormMasonry, 600)
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === 'selectgeolocation' && inputGroup?.value === data?.type) {
          if (inputGroup?.onChange) inputGroup?.onChange(data?.list, formData.value)
        }
      })
    }

    const onScheduleSelected = (data) => {
      if (data?.type && data?.list) {
        formData.value[data?.type + 'List'] = data?.list
        window.setTimeout(helpers.initFormMasonry, 600)
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === 'selectschedule' && inputGroup?.value === data?.type) {
          if (inputGroup?.onChange) inputGroup?.onChange(data?.list, formData.value)
        }
      })
    }

    const onPriceSet = (data) => {
      if (data?.type && data?.data) {
        let isFound = false
        formData.value[data?.type + 'List'].map((item, index) => {
          if (parseInt(item?.CurrencyId) === parseInt(data?.data?.CurrencyId)) {
            formData.value[data?.type + 'List'][index] = { ...item, ...data.data }
            isFound = true
          }
        })

        if (!isFound) formData.value[data?.type + 'List'].push(data?.data)
        window.setTimeout(helpers.initFormMasonry, 600)
      }
    }

    const onProductPriceSet = (data) => {
      if (data?.type && data?.data) {
        let isFound = false
        formData.value[data?.type + 'List'].map((item, index) => {
          if (parseInt(item?.CurrencyId) === parseInt(data?.data?.CurrencyId)) {
            formData.value[data?.type + 'List'][index] = { ...item, ...data.data }
            isFound = true
          }
        })

        if (!isFound) formData.value[data?.type + 'List'].push(data?.data)
        window.setTimeout(helpers.initFormMasonry, 600)
     }
   }

    const onTranslationSet = (data) => {
      if (data?.type && data?.data) {
        let isFound = false
        formData.value[data?.type + 'List'].map((item, index) => {
          if (parseInt(item?.LanguageId) === parseInt(data?.data?.LanguageId)) {
            formData.value[data?.type + 'List'][index] = { ...item, ...data.data }
            isFound = true
          }
        })

        if (!isFound) formData.value[data?.type + 'List'].push(data?.data)
        window.setTimeout(helpers.initFormMasonry, 600)
      }
    }

    const onImageSelected = (data) => {
      if (data?.type && data?.blob) {
        formData.value[data?.type + 'List'].push({ blob: data?.blob, IsDefault: formData.value[data?.type + 'List'].length === 0 ? 1 : 0 })
        window.setTimeout(helpers.initFormMasonry, 600)
      }

      if (data?.type && data?.file) {
        formData[data?.type + 'List'].push({ file: data?.file, image: data?.image, IsDefault: formData[data?.type + 'List'].length === 0 ? 1 : 0 })
        window.setTimeout(helpers.initFormMasonry, 600)
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === 'image' && inputGroup?.value === data?.type) {
          if (inputGroup?.limit && formData.value[data?.type + 'List'].length > inputGroup?.limit) {
            formData.value[data?.type + 'List'].shift()
          }
        }
      })
    }

    const toggleDefaultImage = (type, index) => {
      formData.value[type + 'List'].forEach((item, itemIndex) => {
        if (index !== itemIndex) item.IsDefault = 0
      })

      if (formData.value[type + 'List'][index]?.IsDefault) {
        return (formData.value[type + 'List'][index].IsDefault = 0)
      } else {
        return (formData.value[type + 'List'][index].IsDefault = 1)
      }
    }

    const deleteImage = (type, index, FileId) => {
      if (FileId) {
        formData.value['Delete' + type + 'List'].push({ FileId })
      }

      formData.value[type + 'List'].splice(index, 1)
    }

    // FOR FILE UPLOAD
    const onSelectFile = ($refs, inputGroup) => {
      $refs.fileUploadInput[0].value = ''
      $refs.fileUploadInput[0].click()
    }

    const onFileUploadChange = ($event, inputGroup) => {
      let input = $event.target

      if (input?.files?.[0]) {
        let file = input?.files?.[0]
        formData.value[inputGroup?.value + 'List'].push({ file, IsDefault: 0 })
        window.setTimeout(helpers.initFormMasonry, 600)

        if (inputGroup?.limit && formData.value[inputGroup?.value + 'List'].length > inputGroup?.limit) {
          formData.value[inputGroup?.value + 'List'].shift()
        }
      }
    }

    const onDeleteFile = (type, index, FileId) => {
      if (FileId) {
        formData.value['Delete' + type + 'List'].push({ FileId })
      }

      formData.value[type + 'List'].splice(index, 1)
    }

    const setFormComponentData = async (data, processUpdate = false) => {
      helpers.showLoader()
      formComponentData = data
      helpers.deleteFormData(formData.value)

      let initialFormData = {}
      let requiredHolder = {}

      // DYNAMIC IMPORT OF VALIDATION RULES BASED ON VALIDATION
      if (formComponentData?.validation) {
        validationRuleList = await get('/public/validation/list', { Type: formComponentData?.validation?.toLowerCase() })
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (['image', 'upload'].indexOf(inputGroup?.type) > -1 && inputGroup?.value !== '') {
          let temporaryData = {}
          temporaryData[inputGroup?.value + 'List'] = []
          temporaryData['Delete' + inputGroup?.value + 'List'] = []

          initialFormData = { ...initialFormData, ...temporaryData }
          requiredHolder[inputGroup?.value + 'List'] = {
            required: inputGroup?.required
          }

          isFormUpload.value = true
        }

        if (['price', 'translation', 'selectmultiple', 'selectgeolocation', 'selectschedule','priceProduct'].indexOf(inputGroup?.type) > -1 && inputGroup?.value !== '') {
          let temporaryData = {}
          let inputName = inputGroup?.value + 'List'

          inputListValueMap[inputName] = inputGroup?.params?.valueSelector
          temporaryData[inputName] = []

          initialFormData = { ...initialFormData, ...temporaryData }
          requiredHolder[inputName] = {
            required: inputGroup?.required
          }

          if (inputGroup?.type === 'selectmultiple') hasSelectMultiple.value = true
          if (inputGroup?.type === 'selectgeolocation') hasSelectGeoLocation.value = true
          if (inputGroup?.type === 'selectschedule') hasSelectSchedule.value = true
          if (inputGroup?.type === 'price') hasSetPrice.value = true
          if (inputGroup?.type === 'priceProduct') hasSetProductPrice.value = true
          if (inputGroup?.type === 'translation') hasSetTranslation.value = true
        }

        if (inputGroup?.type === 'input' && inputGroup?.list.length > 0) {
          inputGroup?.list.forEach((inputItem) => {
            let temporaryData = {}

            if (inputItem?.type === 'tags') {
              let inputName = inputItem?.value + 'List'
              temporaryData[inputItem?.value] = ''
              temporaryData[inputName] = inputItem?.defaultValue ? inputItem?.defaultValue : []
            } else {
              temporaryData[inputItem?.value] = inputItem?.defaultValue || inputItem?.defaultValue === 0 ? inputItem?.defaultValue : ''
            }

            initialFormData = { ...initialFormData, ...temporaryData }

            requiredHolder[inputItem?.value] = {
              required: inputItem?.required
            }
          })
        }

        inputGroup.isVisible = true
      })

      Object.assign(formData.value, { ...initialFormData })

      for (let keys in formData.value) {
        let requiredDeclaration = requiredHolder[keys]
        let temporaryValidationRule = { ...requiredDeclaration }

        if (validationRuleList && validationRuleList[keys]) {
          let validationRuleDeclaration = validationRuleList[keys]
          temporaryValidationRule = { ...temporaryValidationRule, ...validationRuleDeclaration }
        }

        validationRules[keys] = temporaryValidationRule
      }

      helpers.resetForm('.formContainer')

      if (processUpdate) {
        isUpdate.value = true
        if (formComponentData?.getLink) getData()
      } else {
        isUpdate.value = false
      }

      if (data?.buttonText) buttonText.value = data?.buttonText
      if (data?.buttonLocation) buttonLocation.value = data?.buttonLocation

      store.dispatch('form/setInputList', formComponentData.inputList)
      store.dispatch('form/setFormId', formComponentData.formId)
      helpers.hideLoader()

      // INITIATE DELAYED PROCEDURES
      window.setTimeout(() => {
        helpers.createTimePicker(
          '.timepicker input',
          (pickerElement, values) => {
            if (values?.length) formData.value[pickerElement?.inputEl?.attributes?.name?.value] = values.join(':')
          },
          { hasSeconds: true }
        )

        helpers.createDurationPicker('.durationpicker input', (pickerElement, values) => {
          if (values?.length) formData.value[pickerElement?.inputEl?.attributes?.name?.value] = values.join(':')
        })

        helpers.createDatePicker('.datepicker input', (pickerElement, values) => {
          if (values?.length >= 3) formData.value[pickerElement?.inputEl?.attributes?.name?.value] = `${values?.[0]} ${values?.[1]}, ${values?.[2]}`
        })

        helpers.createDateTimePicker('.datetimepicker input', (pickerElement, values) => {
          if (values?.length) formData.value[pickerElement?.inputEl?.attributes?.name?.value] = helpers.formatDateTime(values[0])
        })

        Dom7("li[acceptinput='number'] input").on('keypress', (e) => {
          let regex = new RegExp('^[0-9.]+$')
          let character = String.fromCharCode(!e.charCode ? e.which : e.charCode)

          if (!regex.test(character)) {
            e.preventDefault()
          }
        })

        Dom7("li[acceptinput='alphanumeric'] input").on('keypress', (e) => {
          let regex = new RegExp('^[a-zA-Z0-9]+$')
          let character = String.fromCharCode(!e.charCode ? e.which : e.charCode)

          if (!regex.test(character)) {
            e.preventDefault()
          }
        })
      }, 800)
    }

    const getData = async () => {
      let getDataReturn = await get(formComponentData?.getLink, formComponentData?.getParams)

      if (getDataReturn) {
        for (let formKey in formData.value) {
          if (getDataReturn && getDataReturn[formKey]) {
            if (formKey.indexOf('Date') > -1 && !helpers.isBlank(getDataReturn[formKey])) {
              formData.value[formKey] = helpers.formatJSDate(getDataReturn[formKey])
            } else {
              formData.value[formKey] = getDataReturn[formKey]
            }
          }
        }
      }
    }

    const onSelectMultipleSort = async ({ from, to }, selector) => {
      formData.value[selector].splice(to, 0, formData.value[selector].splice(from, 1)[0])
    }

    // TAGS ACTIONS
    const onAddTag = (type, unique = false) => {
      if (type && formData.value[type + 'List'] && formData.value[type] != '') {
        if (unique && formData.value[type + 'List'].indexOf(formData.value[type]) >= 0) {
          return false
        }

        formData.value[type + 'List'].push(formData.value[type])
        formData.value[type] = ''
      }
    }

    const onDeleteTag = (type, item, index) => {
      formData.value[type + 'List'].splice(index, 1)
    }

    const onFormSubmit = (event) => {
      event.preventDefault()
      onProcessSave()
    }

    const onProcessSave = async () => {
      let isValid = validate(true)
      let isCustomValidation = true

      if (formComponentData?.saveValidation && typeof formComponentData?.saveValidation === 'function') {
        isCustomValidation = false
        isCustomValidation = formComponentData?.saveValidation(formData.value)
      }

      if (isValid && isCustomValidation) {
        isButtonProcessing.value = true
        const saveFormData = new FormData()
        const processFormData = {}

        if (!helpers.isEmpty(formComponentData?.saveParams)) {
          for (let itemKey in formComponentData?.saveParams) {
            formData.value[itemKey] = formComponentData?.saveParams[itemKey]
          }
        }

        // PROCESS FORM DATA
        for (let formItem in formData.value) {
          if (formItem.indexOf('Date') > -1 || formItem.indexOf('StartDate') > -1 || formItem.indexOf('EndDate') > -1) {
            if (helpers.isBlank(formData.value[formItem])) {
              processFormData[formItem] = null
            } else {
              processFormData[formItem] = helpers.formatSQLDate(formData.value[formItem])
            }
          } else if (formItem.indexOf('Price') > -1) {
            let tmpData = []

            formData.value[formItem].forEach((item) => {
              let tmpObject = {
                CurrencyId: item.CurrencyId,
                StatusCode: item.StatusCode
              }

              for (let key in item) {
                if (key.indexOf('Price') > -1) tmpObject[key] = item[key]
              }

              tmpData.push(tmpObject)
            })

            processFormData[formItem] = tmpData
          } else if (formItem.indexOf('Translation') > -1) {
            processFormData[formItem] = formData[formItem]
          } else if (formItem.indexOf('Image') > -1 && formItem.indexOf('Delete') < 0) {
            processFormData[formItem] = formData.value[formItem]
          } else if (formItem.indexOf('File') > -1 && formItem.indexOf('Delete') < 0) {
            processFormData[formItem] = formData.value[formItem]
          } else if (formItem.indexOf('Delete') > -1) {
            processFormData[formItem] = formData.value[formItem]
          } else if (formItem.indexOf('MobileCode') > -1) {
            processFormData[formItem] = helpers.isBlank(formData.value['MobileNumber']) ? null : formData.value[formItem]
          } else if (formItem.indexOf('GeoLocation') > -1) {
            processFormData[formItem] = formData.value[formItem]
          } else if (formItem.indexOf('Schedule') > -1) {
            processFormData[formItem] = formData.value[formItem]
          } else if (formItem.indexOf('BookingLimitPerDay') > -1) {
            processFormData[formItem] = helpers.isBlank(formData.value['BookingLimitPerDay']) ? 0 : formData.value[formItem]
          } else if (formItem.indexOf('BookingLimitPerTimeSlot') > -1) {
            processFormData[formItem] = helpers.isBlank(formData.value['BookingLimitPerTimeSlot']) ? 0 : formData.value[formItem]
          } else if (formItem.indexOf('Position') > -1) {
            processFormData[formItem] = helpers.isBlank(formData.value['Position']) ? 0 : formData.value[formItem]
          } else if (formItem.indexOf('List') > -1 && formItem.indexOf('GeoLocation') < 0 && formItem.indexOf('Schedule') < 0) {
            if (inputListValueMap[formItem]) {
              let tmpData = []

              formData.value[formItem].forEach((item, index) => {
                let tmpObject = {}

                tmpObject[inputListValueMap[formItem]] = item[inputListValueMap[formItem]]
                tmpObject['Position'] = index + 1

                tmpData.push(tmpObject)
              })

              processFormData[formItem] = tmpData
            } else {
              processFormData[formItem] = formData.value[formItem]
            }
          } else {
            processFormData[formItem] = formData.value[formItem]
          }
        }

        if (isFormUpload.value === true) {
          for (let formItem in processFormData) {
            if ((formItem.indexOf('Image') > -1 || formItem.indexOf('File') > -1) && formItem.indexOf('Delete') < 0 && formItem.indexOf('Id') < 0) {
              let tmpData = []

              processFormData[formItem].forEach((item, index) => {
                if (item?.blob) {
                  saveFormData.append(`${formItem?.replace('List', '')}-${index}${item.IsDefault === 1 ? '-DEFAULT' : ''}`, item?.blob)
                } else if (item?.file) {
                  saveFormData.append(`${formItem?.replace('List', '')}-${index}${item.IsDefault === 1 ? '-DEFAULT' : ''}`, item?.file)
                } else {
                  tmpData.push(item)
                }
              })

              saveFormData.append(formItem, JSON.stringify(tmpData))
            } else if (formItem.indexOf('List') > -1) {
              saveFormData.append(formItem, JSON.stringify(processFormData[formItem]))
            } else {
              saveFormData.append(formItem, processFormData[formItem])
            }
          }
        }

        let formSaveReturn = await post(formComponentData?.saveLink, isFormUpload.value === true ? saveFormData : processFormData)
        isButtonProcessing.value = false

        if (formSaveReturn) {
          helpers.createNotification({
            type: 'info',
            title: $t.getTranslation('MSG_INFO'),
            message: isUpdate.value === true ? formComponentData?.successUpdateMessage : formComponentData?.successCreateMessage
          })

          store.dispatch('form/setInputList', [])
          emit('successSave', { isSave: true, ...formSaveReturn })
        }
      }
    }

    return {
      isServicePartner,
      formId,
      inputList,
      hasSelectMultiple,
      hasSelectGeoLocation,
      hasSelectSchedule,
      hasSetPrice,
      hasSetProductPrice,
      hasSetTranslation,
      isButtonProcessing,
      buttonText,
      buttonLocation,
      onTogglePassword,
      onToggleVisibility,
      onInputBlur,
      onMultipleSelected,
      onGeoLocationSelected,
      onScheduleSelected,
      onImageSelected,
      onPriceSet,
      onTranslationSet,
      onAddTag,
      onDeleteTag,
      toggleDefaultImage,
      deleteImage,
      onSelectFile,
      onProductPriceSet,
      onFileUploadChange,
      onDeleteFile,
      formData,
      validationRules,
      setFormComponentData,
      getData,
      onSelectMultipleSort,
      onFormSubmit,
      onProcessSave
    }
  },
  methods: {
    onSelectImage(type) {
      const self = this

      if (type) {
        self.inputList.forEach((inputGroup) => {
          if (inputGroup?.type === 'image' && inputGroup?.value === type) {
            if (inputGroup?.limit && self.formData[type + 'List'].length >= inputGroup?.limit) {
              throw new Error(self.$t.getTranslation('ERR_MAXIMUM_UPLOAD_LIMIT_EXCEED'))
            }
          }
        })
      }

      self.$refs.imageUploadPopupComponent.forEach((imageComponent) => {
        if (imageComponent?.params?.type == type) imageComponent.openPopup(type)
      })
    },
    onSelectItem(type) {
      this.$refs.selectItemPopupComponent.openPopup(type)
    },
    onSelectGeoLocation(inputGroup) {
      if (this.hasSelectGeoLocation) this.$refs.selectGeoLocationPopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + 'List'])
    },
    onSelectSchedule(inputGroup) {
      if (this.hasSelectSchedule) this.$refs.selectSchedulePopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + 'List'])
    },
    onSelectMultiple(inputGroup) {
      if (this.hasSelectMultiple) this.$refs.selectMultiplePopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + 'List'])
    },
    onSetPrice(inputGroup) {
      if (this.hasSetPrice) this.$refs.setPricePopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + 'List'])
    },
    onSetProductPrice(inputGroup) {
      console.log(inputGroup);
      if (this.hasSetProductPrice) this.$refs.setPriceProductPopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + 'List'])
    },
    onEditPrice(inputGroup, item, index) {
      if (this.hasSetPrice) this.$refs.setPricePopupComponent.openPopup(inputGroup, item)
    },
    onDeletePrice(inputGroup, item, index) {
      this.formData[inputGroup?.value + 'List'].splice(index, 1)
    },
    onSetTranslation(inputGroup) {
      if (this.hasSetTranslation) this.$refs.setTranslationPopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + 'List'])
    },
    onEditTranslation(inputGroup, item, index) {
      if (this.hasSetTranslation) this.$refs.setTranslationPopupComponent.openPopup(inputGroup, item)
    },
    onDeleteTranslation(inputGroup, item, index) {
      this.formData[inputGroup?.value + 'List'].splice(index, 1)
    }
  }
})
</script>
