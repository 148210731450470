import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

// DECLARATION FOR REGULAR ICONS
import {
  // NAVIGATION COMPONENT ICONS
  faBars,
  faAngleUp,
  faLongArrowLeft,
  faBell,
  faXmark,
  faPowerOff,
  faUser,
  faHeart,
  faCoffee,
  faExpand,
  faCompress,
  // DATA TABLE COMPONENT ICONS
  faMagnifyingGlass,
  faUndo,
  faGears,
  faPencil,
  faExchange,
  faTrash,
  faTable,
  // IMAGE UPLOAD COMPONENT ICONS
  faArrowsRotate,
  faArrowRightArrowLeft,
  faImages,
  // VIDEO UPLOAD COMPONENT ICONS
  faVideo,
  // BUTTON ICONS
  faGear,
  // TABLE ICONS
  faPaperPlane,
  // FORM INPUTS
  faPlus,
  faStar,
  // CONVERSATION ICONS
  faCamera,
  faLink,
  faEllipsis,
  // SCHEDULE ICONS
  faAlarmPlus
} from '@fortawesome/pro-regular-svg-icons'

// DECLARATION FOR DUOTONE ICONS
import {
  // NAVIGATION ICONS
  faChartArea as fadChartArea,
  faFile as fadFile,
  faMoneyCheckDollar as fadMoneyCheckDollar,
  faBookmark as fadBookmark,
  faComments as fadComments,
  faCalendarStar as fadCalendarStar,
  faLayerGroup as fadLayerGroup,
  faGiftCard as fadGiftCard,
  faStore as fadStore,
  faUserGroupCrown as fadUserGroupCrown,
  faList as fadList,
  faGear as fadGear,
  faUserDoctorMessage as fadUserDoctorMessage,
  faPersonBooth as fadPersonBooth,
  faUsersGear as fadUsersGear,
  faMap as fadMap,
  faStarSharpHalf as fadStarSharpHalf,
  faCartShopping as fadCartShopping,
  // DASHBOARD ICONS
  faCloudsSun as fadCloudsSun,
  faCoins as fadCoins
} from '@fortawesome/pro-duotone-svg-icons'

// DECLARATION FOR SOLID ICONS
import { faStar as fasStar, faFile as fasFile, faImage as fasImage, faHeartPulse as fasHeartPulse, faArrowTrendUp, faArrowTrendDown, faEye as fasEye } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// ADD REGULAR ICONS
library.add(
  faBars,
  faAngleUp,
  faLongArrowLeft,
  faBell,
  faXmark,
  faPowerOff,
  faUser,
  faHeart,
  faCoffee,
  faExpand,
  faCompress,
  faMagnifyingGlass,
  faUndo,
  faGears,
  faPencil,
  faExchange,
  faTrash,
  faTable,
  faArrowsRotate,
  faArrowRightArrowLeft,
  faImages,
  faVideo,
  faGear,
  faPaperPlane,
  faPlus,
  faStar,
  faCamera,
  faLink,
  faEllipsis,
  faAlarmPlus,
  faCartShopping
)

// ADD DUOTONES ICONS
library.add(
  fadChartArea,
  fadFile,
  fadMoneyCheckDollar,
  fadBookmark,
  fadComments,
  fadCalendarStar,
  fadLayerGroup,
  fadGiftCard,
  fadStore,
  fadUserGroupCrown,
  fadList,
  fadGear,
  fadUserDoctorMessage,
  fadPersonBooth,
  fadUsersGear,
  fadMap,
  fadStarSharpHalf,
  fadCloudsSun,
  fadCartShopping,
  fadCoins
)

// ADD SOLID ICONS
library.add(fasStar, fasFile, fasImage, fasHeartPulse, faArrowTrendUp, faArrowTrendDown, fasEye)

dom.watch()

export { FontAwesomeIcon }
