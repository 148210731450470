import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'product',
    path: '/product/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'cart-shopping',
        title: translation.getTranslation('PNL_NAV_PRODUCT', 'Product'),
        activeLink: 'product'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_SERVICE']
    },
    beforeEnter: [checkMeta],
    routes: [
      {
        name: 'productList',
        path: 'product/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_PRODUCT_LIST', 'Product List'),
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_SERVICE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductListPage.vue')
      },
      {
        name: 'deliveryList',
        path: 'delivery/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Delivery List',
            activeLink: 'deliveryList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_EXPERT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/delivery/deliveryListPage.vue')
      }
    ]
  }
]

export default routes
