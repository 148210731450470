import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'integrations',
    path: '/integrations/',
    icon: 'gear',
    meta: {
      navigation: {
        isShown: true,
        title: translation.getTranslation('PNL_NAV_INTEGRATIONS', 'Integrations'),
        activeLink: 'integrations'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_USER']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/integrations/IntegrationsPage.vue')
  }
]

export default routes
